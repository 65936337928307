html {
  font-size: 16px;
}
body {
  margin: 0;
  color: #b1c3d6;
  font-family: "interstate", sans-serif;
  font-weight: 300;
}
html {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
html::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.embed-container {
  --video--width: 1296;
  --video--height: 540;

  position: relative;
  padding-bottom: calc(
    var(--video--height) / var(--video--width) * 100%
  ); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  pointer-events: none;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor {
  pointer-events: none;
}
.cursor__ball {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  /* mix-blend-mode: screen; */
}
body .cursor__ball circle {
  fill: #f7f8fa;
}

.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}

@media (max-width: 1024px) {
  .cursor {
    display: none;
  }
}
